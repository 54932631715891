import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Menu, X } from "lucide-react";

const GlobalNav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  const onMobileMenuButtonClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  if (location.pathname === "/waitlist/embedded") return <></>;

  return (
    <>
      <GlobalNavWrapper>
        <DesktopWrapper>
          <LeftNavigationBarWrapper>
            <LeftNavigationButton selected={true} to="/">
              Home
            </LeftNavigationButton>
            <LeftNavigationButton to="/faq">FAQ</LeftNavigationButton>
            <LeftNavigationButton to="/mitgliedschaft">
              Mitgliedschaft
            </LeftNavigationButton>
          </LeftNavigationBarWrapper>

          <RightNavigationBarWrapper>
            <RightNavigationButton to="/warteliste">
              Warteliste
            </RightNavigationButton>
          </RightNavigationBarWrapper>
        </DesktopWrapper>

        <MobileWrapper>
          <MobileMenuButton onClick={() => onMobileMenuButtonClick()}>
            <MobileMenuHamburgerIcon />
          </MobileMenuButton>
        </MobileWrapper>
      </GlobalNavWrapper>

      {mobileMenuOpen && (
        <MobileMenuWrapper>
          <MobileNavigationCloseButton onClick={() => setMobileMenuOpen(false)}>
            <MobileMenuCloseIcon />
          </MobileNavigationCloseButton>

          <MobileNavigationButton
            to="/"
            onClick={() => setMobileMenuOpen(false)}
          >
            Home
          </MobileNavigationButton>
          <MobileNavigationButton
            to="/faq"
            onClick={() => setMobileMenuOpen(false)}
          >
            FAQ
          </MobileNavigationButton>
          <MobileNavigationButton
            to="/mitgliedschaft"
            onClick={() => setMobileMenuOpen(false)}
          >
            Mitgliedschaft
          </MobileNavigationButton>
          <MobileNavigationButton
            to="/warteliste"
            onClick={() => setMobileMenuOpen(false)}
          >
            Warteliste
          </MobileNavigationButton>
        </MobileMenuWrapper>
      )}
    </>
  );
};

export default GlobalNav;

const GlobalNavWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DesktopWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    visibility: hidden;
  }
`;

const LeftNavigationBarWrapper = styled.div`
  height: 50px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.navbar.background_color};

  margin-left: 30px;
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  //filter: drop-shadow(0px 0px 30px rgba(255, 255, 255, 0.3));
`;

const LeftNavigationButton = styled(NavLink)`
  height: 100%;
  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;

  text-decoration: none;
  font-size: 0.85rem;
  font-family: "Heebo", sans-serif;

  color: ${(props) => props.theme.navbar.text_color};

  &.active {
    background-color: ${(props) =>
      props.theme.navbar.button_selected_background_color};

    color: ${(props) => props.theme.navbar.button_selected_text_color};
  }
`;

const RightNavigationBarWrapper = styled(LeftNavigationBarWrapper)`
  background-color: transparent;
  margin-right: 30px;
`;

const RightNavigationButton = styled(LeftNavigationButton)`
  background-color: ${(props) => props.theme.primary_color};
`;

export const TopSpacer = styled.div`
  height: 140px;
  width: 100%;
  background-color: ${(props) => props.theme.background_color};

  @media (max-width: 768px) {
    height: 80px;
  }
`;

const MobileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 60px;

  @media (min-width: 768px) {
    visibility: hidden;
  }
`;

const MobileMenuButton = styled.button`
  unset: all;
  position: absolute;
  top: 10px;
  left: 10px;

  -webkit-tap-highlight-color: transparent;

  background-color: transparent;
  outline: none;
  border: none;

  width: 40px;
  height: 40px;
`;

const MobileMenuHamburgerIcon = styled(Menu)`
  width: 24px;
  color: ${(props) => props.theme.navbar.text_color};
`;

const MobileMenuWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 101;
  background-color: ${(props) => props.theme.navbar.background_color};

  padding: 20px 10px;
  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  overflow: hidden;
`;

const MobileNavigationCloseButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  unset: all;
  outline: none;
  border: none;

  -webkit-tap-highlight-color: transparent;

  margin-bottom: 20px;
`;

const MobileMenuCloseIcon = styled(X)`
  width: 24px;
  color: ${(props) => props.theme.navbar.text_color};
`;

const MobileNavigationButton = styled(NavLink)`
  height: 50px;
  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;

  text-decoration: none;
  font-size: 0.85rem;
  font-family: "Heebo", sans-serif;

  color: ${(props) => props.theme.navbar.text_color};

  &.active {
    background-color: ${(props) =>
      props.theme.navbar.button_selected_background_color};

    color: ${(props) => props.theme.navbar.button_selected_text_color};
  }
`;
