import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Textfield from "../components/Textfield";
import Waitlistslider from "../components/Waitlistslider";
import Waitlistoptionslector from "../components/Waitlistoptionselector";
import Checkbox from "../components/Checkbox";

import { ChevronLeft } from "lucide-react";

import loadingAnimation from "../assets/animations/3-dots-fade.svg";
import { TopSpacer } from "../components/GlobalNav";

//psinner: https://github.com/n3r4zzurr0/svg-spinners/blob/main/svg-css/3-dots-fade.svg

const Warteliste = ({ embedded }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const embeddedFlag = embedded;

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [newsletter, setNewsletter] = useState(true);
  const [germanResidency, setGermanResidency] = useState(false);

  const [selectedOptionAnotherCSC, setSelectedOptionAnotherCSC] = useState({
    value: "no",
    userText: "",
  });

  const [selectedOptionContribution, setSelectedOptionContribution] = useState({
    value: "member",
    userText: "",
  });

  const [amountB, setAmountB] = useState(0);
  const [amountH, setAmountH] = useState(0);

  const [financialSupport, setFinancialSupport] = useState(0);

  const [consentAgb, setConsentAgb] = useState(false);

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    germanResidency: "",
    consentAgb: "",
  });
  const [requestStatus, setRequestStatus] = useState("idle");
  const [requestError, setRequestError] = useState("");

  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const germanResidencyRef = useRef(null);
  const consentAgbRef = useRef(null);

  const liveValidateUsername = (username) => {
    const lengthValidation = username.length >= 6;
    const noSpacesValidation = !username.includes(" ") && username.length > 0;

    setUsername(username);

    const validationJson = {
      valid: lengthValidation && noSpacesValidation,
      validationSteps: [
        {
          label: "mindestens 6 Zeichen",
          valid: lengthValidation,
        },
        {
          label: "keine Leerzeichen",
          valid: noSpacesValidation,
        },
      ],
    };

    return validationJson;
  };

  const liveValidateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailValidation = emailRegex.test(email);

    setEmail(email);

    const validationJson = {
      valid: emailValidation,
      validationSteps: [
        {
          label: "gültige E-Mail Adresse",
          valid: emailValidation,
        },
      ],
    };

    return validationJson;
  };

  const checkAndSubmit = () => {
    /* Run checks */
    if (requestStatus === "loading") return;

    let errors = {
      username: "",
      email: "",
      germanResidency: "",
      consentAgb: "",
    };

    /* username and email using the live validaton function */

    const usernameValidation = liveValidateUsername(username);
    const emailValidation = liveValidateEmail(email);

    if (!usernameValidation.valid) {
      errors.username = "Bitte überprüfe deinen Benutzernamen";
    }

    if (!emailValidation.valid) {
      errors.email = "Bitte überprüfe deine E-Mail Adresse";
    }

    if (germanResidency === false) {
      errors.germanResidency = "Bitte bestätige, dass du in Deutschland wohnst";
    }

    if (consentAgb === false) {
      errors.consentAgb =
        "Bitte bestätige, dass du die AGB und Datenschutzerklärung akzeptierst";
    }

    if (
      errors.username ||
      errors.email ||
      errors.germanResidency ||
      errors.consentAgb
    ) {
      setErrors(errors);

      if (errors.username) {
        if (usernameRef.current)
          usernameRef.current.scrollIntoView({ behavior: "smooth" });
        return;
      } else if (errors.email) {
        if (emailRef.current)
          emailRef.current.scrollIntoView({ behavior: "smooth" });
        return;
      } else if (errors.germanResidency) {
        if (germanResidencyRef.current)
          germanResidencyRef.current.scrollIntoView({ behavior: "smooth" });
        return;
      } else if (errors.consentAgb) {
        if (consentAgbRef.current)
          consentAgbRef.current.scrollIntoView({ behavior: "smooth" });
        return;
      }
    }

    /* Submit data */

    const data = {
      username: username,
      email: email,
      newsletter: newsletter,
      germanResidency: germanResidency,
      alreadyCsc: selectedOptionAnotherCSC.value,
      typeOfParticipation: selectedOptionContribution.value,
      typeOfParticipationText: selectedOptionContribution.userText,
      monthlyAmountB: amountB,
      monthlyAmountH: amountH,
      monthlyContribution: financialSupport,
    };

    setRequestStatus("loading");

    try {
      const httpEndpoint = "https://signupwaitlist-i3x3hibd6a-ew.a.run.app";
      fetch(httpEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.status === 201) {
            setRequestStatus("success");
          } else {
            setRequestStatus("error");
            if (response.status === 400) {
              const responseText = await response.text();
              setRequestError(responseText);
            } else {
              setRequestError(
                "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut",
              );
            }
          }
        })
        .catch((error) => {
          setRequestStatus("error");
        });
    } catch (error) {
      setRequestStatus("error");
    }
  };

  return (
    <WartelisteWrapper embedded={embeddedFlag}>
      <base target="_parent"></base>
      {false && <HeaderPlaceholder />}

      {requestStatus === "success" && (
        <SuccessMessage embedded={embeddedFlag} />
      )}

      <WartelisteCenterWrapper>
        {false && (
          <>
            <h1>Warteliste</h1>
            <h3>
              Um Vorabmitglied zu werden kannst du dich hier registrieren.
              Weitere Infos findest du unten.
            </h3>
          </>
        )}

        {requestStatus !== "success" && (
          <>
            {embeddedFlag && <GoBackButon />}

            {!embeddedFlag && <TopSpacer />}

            <PageDescription>
              <b>Bald können wir endlich zusammen in Dortmund Gras anbauen.</b>
              <br /> <br />
              Ab dem 1.7. werden wir den Club vorraussichtlich für eine
              Anbauerlaubnis anmelden können. Bis dahin gibt es noch vieles
              vorzubereiten, dabei können wir deine Unterstützung gebrauchen.
              Als erstes brauchen wir ein paar Infos über dich, damit der Club,
              sobald es losgeht, die beste Auswahl an Produkten anbieten kann.
              Du musst dich hier noch mit nichts festlegen, wir brauchen nur
              eine grobe Übersicht um planen zu können.
              <br /> <br />
              Falls du noch Fragen hast, schreib uns gerne auf{" "}
              <a href="https://www.instagram.com/sportzigarette.csc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                Insta
              </a>{" "}
              oder eine <a href="mailto:420@csc-sportzigarette.de">Mail</a>. ‍
              <br /> <br />
            </PageDescription>

            <Description>
              Uns ist es sehr wichtig deine Daten zu schützen, alle
              Informationen über dich werden nur genutzt um deine Mitgliedschaft
              zu bearbeiten.
            </Description>

            <Spacer />

            <div style={{ height: "20px" }} />

            <SectionTitle>Kontaktdaten</SectionTitle>
            <SectionDivider />

            <Textfield
              label="Benutzername"
              required
              liveValidationFunction={liveValidateUsername}
              description="Deinen Benutzernahmen darfst du frei wählen und auch Pseudonyme sind möglich."
              error={errors.username}
              type="text"
              ref={usernameRef}
            />

            <Textfield
              label="E-Mail"
              required
              liveValidationFunction={liveValidateEmail}
              description="Wir schicken dir eine Benachrichtigung, sobald wir bereit sind dich als Mitglied aufzunehmen. Deine Email Adresse musst du im nächsten Schritt bestätigen."
              error={errors.email}
              type="email"
              ref={emailRef}
            />

            <Checkbox
              label="Newsletter abonieren"
              defaultOption={newsletter}
              onChange={(value) => setNewsletter(value)}
            />

            <Spacer h="10px" />

            <Checkbox
              label="Wohnsitz in Deutschland"
              onChange={(value) => setGermanResidency(value)}
              defaultOption={germanResidency}
              error={errors.germanResidency}
              ref={germanResidencyRef}
            >
              Um Mitglied zu werden musst du einen festen Wohnsitz in
              Deutschland haben.
            </Checkbox>

            <Spacer h="60px" />
            <SectionTitle>Deine Mitgliedschaft</SectionTitle>
            <SectionDivider />

            <Waitlistoptionslector
              label="Bestehende Mitgliedschaft"
              options={[
                {
                  label: "Ja",
                  value: "yes",
                  userText: false,
                },
                {
                  label: "Nein",
                  value: "no",
                  userText: false,
                  userTextLabel: "",
                },
                {
                  label: "Ich habe mich beworben",
                  value: "awaiting",
                  userText: false,
                },
              ]}
              defaultOption={1}
              onChange={(value) => setSelectedOptionAnotherCSC(value)}
            >
              Bist du schon Mitglied in einem anderen CSC?
            </Waitlistoptionslector>

            <Waitlistoptionslector
              label="Art der Beteiligung"
              options={[
                {
                  label: "nur Mitgliedschaft",
                  description: "und an der Ernte beteiligt werden",
                  value: "member",
                  userText: false,
                },
                {
                  label: "Mithelfen",
                  description: "und an der Ernte beteiligt werden",
                  value: "contributor",
                  userText: true,
                  userTextLabel: "am liebsten im Bereich (optional)",
                },
              ]}
              onChange={(value) => setSelectedOptionContribution(value)}
            >
              Wir können Unterstützung in allen Bereichen gebrauchen. Über die
              Buchhaltung, bis zur Beschaffung deiner lieblings Strains oder dem
              Bau der Growrooms. Nach der Legalisierung sind ausserdem
              Festanstellungen in allen Bereichen möglich.
            </Waitlistoptionslector>

            <Waitlistslider
              label="Abnahmemenge Blüten"
              labelAddition="(monatlich)"
              description="Diese Angabe ist natürlich nicht verbindlich, aber hilft uns besser zu
          planen. Wenn du nicht an Blüten, sonder nur an Hasch interessiert bist,
          kannst du hier einfach 0g angeben."
              unit="gramm"
              icon="🥦"
              min={0}
              max={50}
              onChange={(value) => setAmountB(value)}
            />

            <Waitlistslider
              label="Abnahmemenge Hasch/Rosin/Dabs"
              labelAddition="(monatlich)"
              description="Diese Angabe ist natürlich nicht verbindlich, aber hilft uns besser zu
          planen. Wenn du nur an Blüten interessiert bist, kannst du hier einfach 0g angeben."
              unit="gramm"
              icon="🥦"
              min={0}
              max={50}
              onChange={(value) => setAmountH(value)}
            />

            <Waitlistslider
              label="Finanzielle Unterstützung"
              labelAddition="(vorab | monatlich)"
              description=""
              unit="€"
              icon=""
              min={0}
              max={100}
              onChange={(value) => setFinancialSupport(value)}
            >
              <p>
                Bewerber die schon vor dem 1.7. einen monatlichen
                Mitgliedsbeitrag leisten wollen, werden auf der Warteliste
                bevorzugt. <br /> Als Dankeschön erhälst du für jeden
                beigetragenen Euro Bonuspunkte, die du später einsetzen kannst.
                Zusätzlich bekommst du ein kleines Kiffer Paket von uns
                geschenkt. <br /> <br /> Je mehr Mitglieder sich beteiligen,
                desto besser wird unsere Produktauswahl sobald es los geht
              </p>
            </Waitlistslider>

            <Spacer h="60px" />
            <SectionTitle>Einverständnis</SectionTitle>
            <SectionDivider />

            <Checkbox
              label="Datenschutzerklärung akzeptieren"
              defaultOption={consentAgb}
              onChange={(value) => setConsentAgb(value)}
              error={errors.consentAgb}
              ref={consentAgbRef}
            >
              Ich habe die
              <DatenschutzLink href="https://www.csc-sportzigarette.de/datenschutz">
                {" "}
                Datenschutzerklärung{" "}
              </DatenschutzLink>{" "}
              gelesen und akzeptiere sie.
            </Checkbox>

            <Spacer h="30px" />

            <SubmitButton
              onClick={() => checkAndSubmit()}
              greyedOut={requestStatus === "loading"}
            >
              Let's Grow!
              {requestStatus === "loading" && (
                <motion.div
                  style={{
                    overflow: "hidden",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                  initial={{ width: 0 }}
                  animate={{ width: 18 }}
                >
                  <LoadingAmination src={loadingAnimation} />
                </motion.div>
              )}
            </SubmitButton>
            {requestStatus === "error" && <Error>{requestError}</Error>}
          </>
        )}

        <Spacer h="100px" />
      </WartelisteCenterWrapper>
    </WartelisteWrapper>
  );
};

export default Warteliste;

const WartelisteWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${(props) => props.theme.background_color};
  color: ${(props) => props.theme.title_color};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WartelisteCenterWrapper = styled.div`
  height: 100%;
  width: 50%;

  max-width: 950px;

  @media (max-width: 1500px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 85%;
  }
`;

const SectionTitle = styled.p`
  color: ${(props) => props.theme.textfield.title_color};s
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.divider_color};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Spacer = styled.div`
  height: ${(props) => props.h || "20px"};
`;

const SubmitButton = styled.button`
  unset: all;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 13px 20px;

  border-radius: 7px;
  background-color: ${(props) =>
    props.theme.buttons_new.dominant.background_color};

  color: ${(props) => props.theme.buttons_new.dominant.text_color};
  font-size: 0.9rem;
  font-weight: 500;

  ${(props) =>
    props.greyedOut &&
    `
    opacity: 0.8;
    pointer-events: none;
    `};
`;

const LoadingAmination = styled.img`
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  color: ${(props) => props.theme.buttons_new.dominant.text_color};
`;

const Description = styled.p`
  width: 80%;
  color: ${(props) => props.theme.textfield.description_color};
  font-size: 14px;
  font-size: 0.85rem;
  margin-top: 10px;

  a {
    cursor: pointer;
    color: ${(props) => props.theme.textfield.description_color};
    text-decoration: underline;
  }
`;

const PageDescription = styled(Description)`
  @media (max-width: 768px) {
    width: 100%;
  }
  font-size: 1rem;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  color: ${(props) => props.theme.description_color};

  a {
    color: ${(props) => props.theme.description_color};
  }
`;

const Error = styled(Description)`
  color: ${(props) => props.theme.textfield.validation.invalid_color};
`;

const DatenschutzLink = styled.a`
  color: ${(props) => props.theme.primary_color};
`;

const HeaderPlaceholder = () => (
  <HeaderWrapper>
    <HeaderTitle>{"<Hier Header vorstellen>"}</HeaderTitle>
  </HeaderWrapper>
);

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  background-color: #b3b3b3;
  color: ${(props) => props.theme.title_color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  font-size: 1.3rem;
  display: inline;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const SuccessMessage = ({ embedded }) => {
  return (
    <SuccessWrapper>
      <Spacer h="50px" />
      <SuccessTitle>Vielen Dank für deine Anmeldung!</SuccessTitle>
      <SuccessDescription>
        Wir haben deine Anmeldung erhalten und melden uns bei dir sobald es los
        geht.
        <br /> <br />
        Bitte überprüfe dein E-Mail Postfach und den Spam Ordner. Wenn du deine
        E-Mail Adresse nicht bestätigst, können wir dich nicht auf dem Laufenden
        halten und deine Daten werden automatisch in 3 Tagen gelöscht.
      </SuccessDescription>

      <Spacer h="50px" />

      <BackHomeButtonWrapper>
        {embedded ? (
          <BackHomeButton href="https://www.csc-sportzigarette.de">
            Zurück zur Startseite
          </BackHomeButton>
        ) : (
          <BackHomeLink to="/">Zurück zur Startseite</BackHomeLink>
        )}
      </BackHomeButtonWrapper>
    </SuccessWrapper>
  );
};

const SuccessWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 80%;
  }
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessTitle = styled.p`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  text-align: left;
  color: ${(props) => props.theme.title_color};
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SuccessDescription = styled.p`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.description_color};
  font-size: 0.9rem;
  margin-top: 10px;
`;

const BackHomeButtonWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  justify-content: flex-start;
`;

const BackHomeButton = styled.a`
  unset: all;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 13px 20px;

  border-radius: 7px;
  background-color: ${(props) =>
    props.theme.buttons_new.dominant.background_color};

  color: ${(props) => props.theme.buttons_new.dominant.text_color};
  font-size: 0.9rem;
  font-weight: 500;

  ${(props) =>
    props.greyedOut &&
    `
    opacity: 0.8;
    pointer-events: none;
    `};
`;

const BackHomeLink = styled(Link)`
  unset: all;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 13px 20px;

  border-radius: 7px;
  background-color: ${(props) =>
    props.theme.buttons_new.dominant.background_color};

  color: ${(props) => props.theme.buttons_new.dominant.text_color};
  font-size: 0.9rem;
  font-weight: 500;

  ${(props) =>
    props.greyedOut &&
    `
    opacity: 0.8;
    pointer-events: none;
    `};
`;

const GoBackButon = () => {
  return (
    <GoBackButtonWrapper>
      <GoBackButton href="https://www.csc-sportzigarette.de">
        <GoBackIcon />
        zurück
      </GoBackButton>
    </GoBackButtonWrapper>
  );
};

const GoBackButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;
`;

const GoBackIcon = styled(ChevronLeft)`
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const GoBackButton = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.textfield.title_color};
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -10px;
  padding: 0;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
