import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { motion } from "framer-motion";

const WaitlistButton = () => {
  return (
    <StyledWaitlistButton to="/warteliste">Zur Warteliste</StyledWaitlistButton>
  );
};

export default WaitlistButton;

const StyledWaitlistButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 12px 20px;
  margin-top: 25px;

  border-radius: 10px;
  background-color: ${(props) =>
    props.theme.buttons_new.dominant.background_color};

  color: ${(props) => props.theme.buttons_new.dominant.text_color};
  font-size: 0.85rem;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
`;
