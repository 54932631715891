import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { CircleArrowDown } from "lucide-react";

import { TopSpacer } from "../components/GlobalNav";
import {
  HeaderWrapper,
  PageTitle,
  PageDescription,
  PageSubDescription,
  BrandedLink,
} from "../components/PageGenerics";

const FaqScreen = () => {
  const [faqsOpen, setFaqsOpen] = useState([true, false, false, false]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFaqsOpen = (index) => {
    //const newFaqsOpen = [false, false, false, false];
    //newFaqsOpen[index] = true;
    //setFaqsOpen(newFaqsOpen);
  };

  return (
    <FaqWrapper>
      <TopSpacer />

      <HeaderWrapper>
        <PageTitle>Frequently Asked Questions</PageTitle>
        <PageDescription>
          Hier haben wir euch die Antworten auf die wichtigsten Fragen
          zusammengestellt.
        </PageDescription>
        <PageSubDescription>
          Weitere Infos zur Mitgliedschaft findest du{" "}
          <BrandedLink to="/mitgliedschaft">hier</BrandedLink>.
        </PageSubDescription>
      </HeaderWrapper>

      <div style={{ height: "30px" }} />

      <FaqItem
        question="Wann wird das Ott legal?"
        answer="Ab dem 1.4.2024 wird Gras in Deutschland legal, solange am 23.3. im Bundestag alles gut läuft. Du darfst dann bis zu 50 Gramm Blüten oder Hasch besitzen und so viel rauchen wie du willst."
        isAlreadyOpen={faqsOpen[0]}
        index={0}
        onOpen={() => handleFaqsOpen(0)}
      />
      <FaqItem
        question="Woher bekomme ich dann mein Gras?"
        isAlreadyOpen={faqsOpen[1]}
        index={1}
        onOpen={() => handleFaqsOpen(1)}
      >
        Das neue Gesetz gibt dir zwei Möglichkeiten legal an Gras zu kommen.
        Entweder baust du es selber zuhause an, oder du wirst Mitglied in einem
        Cannabis Social Club (CSC).
      </FaqItem>
      <FaqItem
        question="Was ist ein CSC?"
        isAlreadyOpen={faqsOpen[2]}
        index={2}
        onOpen={() => handleFaqsOpen(2)}
      >
        Cannabis Social Clubs sind Vereine mit einer Erlaubnis, Gras anzubauen
        und an ihre Mitglieder abzugeben.
        <br />
        <br />
        Jeder Erwachsene darf Mitglied in einem CSC werden. Du musst auch keine
        besonderen Fähigkeiten mitbringen, es reicht wenn du ab und zu Lust auf
        kiffen hast. Da es nicht erlaubt sein wird Cannabis zu verschicken,
        solltest du dir einen Club in deiner Nähe aussuchen.
        <br />
        <br />
        Bis zum 1. Juli wird es noch nicht möglich sein eine Anbauerlaubnis zu
        erhalten, es gibt also aktuell noch keine komplett aktiven CSCs.
        <br />
        <br />
        Du kannst trotzdem schon bei uns mitmachen!
      </FaqItem>
      <FaqItem
        question="Was sind die Vorteile einer Mitgliedschaft als Selbstanbauer?"
        isAlreadyOpen={faqsOpen[3]}
        index={3}
        onOpen={() => handleFaqsOpen(3)}
      >
        Auch wenn du dich dazu entscheidest selber anzubauen, ist es praktisch,
        Mitglied in einem CSC zu werden, von dort kannst du nähmlich Samen und
        Stecklinge bekommen.
      </FaqItem>

      <div style={{ height: "100px" }} />
    </FaqWrapper>
  );
};

export default FaqScreen;

const FaqWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.background_color};
`;

const FaqItem = ({
  question = "Q",
  answer = "",
  isAlreadyOpen = false,
  onOpen = () => {},
  index = 0,
  children,
}) => {
  const [isOpen, _setIsOpen] = useState(isAlreadyOpen);

  useEffect(() => {
    _setIsOpen(isAlreadyOpen);
  }, [isAlreadyOpen]);

  const setIsOpen = (value) => {
    _setIsOpen(value);
    if (value) onOpen();
  };

  return (
    <FaqItemWrapper
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FaqQuestion>{question}</FaqQuestion>

        <FaqIconWrapper
          initial={{ rotate: -90 }}
          animate={{ rotate: isOpen ? 0 : -90 }}
        >
          <FaqIcon />
        </FaqIconWrapper>
      </div>
      <AnimatePresence initial={isOpen}>
        {isOpen && (
          <FaqAnswer>
            {answer}
            {children}
          </FaqAnswer>
        )}
      </AnimatePresence>
    </FaqItemWrapper>
  );
};

const FaqItemWrapper = styled(motion.button)`
  unset: all;
  outline: none;
  border: none;
  position: relative;
  width: 60%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.faq_item.background_color};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.faq_item.hover_background_color};
  }

  @media (max-width: 768px) {
    width: 92%;
  }
`;

const FaqQuestion = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Heebo", sans-serif;
  color: ${(props) => props.theme.faq_item.title_color};

  transition: color 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1rem;
    font-weight: 550;
  }
`;

const FaqAnswer = styled.p`
  width: 70%;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  color: ${(props) => props.theme.faq_item.text_color};

  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    width: 100%;
  }
`;

const FaqIconWrapper = styled(motion.div)`
  position: relative;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const FaqIcon = styled(CircleArrowDown)`
  height: 100%;
  color: ${(props) => props.theme.faq_item.text_color};
`;
