import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { styled } from "styled-components";

const GlobalImpressum = () => {
  const location = useLocation();
  const path = location.pathname;

  if (
    path == "/" ||
    path == "/faq" ||
    path == "/mitgliedschaft" ||
    path == "/warteliste"
  ) {
    return (
      <ImpressumWrapper>
        <ImpressumColumn>
          <ImpressumRow>
            <ImpressumRowTitle>Legal</ImpressumRowTitle>
            <ImpressumRowLink to="/impressum">Impressum</ImpressumRowLink>
            <ImpressumRowLink to="/datenschutz">
              Datenschutzerklärung
            </ImpressumRowLink>
          </ImpressumRow>

          <ImpressumRow>
            <ImpressumRowTitle>Kontakt</ImpressumRowTitle>
            <ImpressumRowA
              href="https://www.instagram.com/sportzigarette.csc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
            >
              Instagram
            </ImpressumRowA>
            <ImpressumRowA
              href="mailto:420@csc-sportzigarette.de"
              to="/datenschutz"
            >
              Email
            </ImpressumRowA>
          </ImpressumRow>
        </ImpressumColumn>

        <CopyrightText>
          Copyright © 2023 2024 CSC SPORTZIGARETTE E.V.I.G
        </CopyrightText>
      </ImpressumWrapper>
    );
  } else {
    return <></>;
  }
};

export default GlobalImpressum;

const ImpressumWrapper = styled.div`
  width: 100%;
  min-height: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  background-color: ${(props) => props.theme.impressum.background_color};
`;

const ImpressumColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
`;

const ImpressumRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;

const ImpressumRowTitle = styled.h3`
  color: ${(props) => props.theme.impressum.title_color};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImpressumRowLink = styled(NavLink)`
  unset: all;
  text-decoration: none;
  color: ${(props) => props.theme.impressum.link_color};
  font-size: 0.9rem;
  margin-bottom: 2px;
`;

const ImpressumRowA = styled.a`
  unset: all;
  text-decoration: none;
  color: ${(props) => props.theme.impressum.link_color};
  font-size: 0.9rem;
  margin-bottom: 2px;
`;

const CopyrightText = styled.p`
  color: ${(props) => props.theme.impressum.link_color};
  font-size: 0.9rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
