import React, { useState, useEffect } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme_dark, theme_light } from "./theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import GlobalNav from "./components/GlobalNav";
import GlobalImpressum from "./components/GlobalImpressum";

import NotFoundScreen from "./screens/404";
import AgeVerification from "./screens/AgeVerification";
import HomeScreen from "./screens/HomeScreen";
import FaqScreen from "./screens/Faq";
import MitgliedschaftInfo from "./screens/MitgliedschaftInfo";

import Warteliste from "./screens/Warteliste";
import WartelisteMailBestätigung from "./screens/WartelisteMailBestätigung";
import WartelisteBenutzerEntfernen from "./screens/WartelisteBenutzerEntfernen";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);

  useEffect(() => {
    const matchDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(matchDarkMode.matches);

    const handleThemeChange = (e) => setDarkMode(e.matches);
    matchDarkMode.addEventListener("change", handleThemeChange);

    const ageVerification = localStorage.getItem("ageVerified");
    if (ageVerification) {
      setAgeVerified(true);
    }

    return () => matchDarkMode.removeEventListener("change", handleThemeChange);
  }, []);

  const verifyAge = () => {
    setAgeVerified(true);
    localStorage.setItem("ageVerified", true);
  };

  const GlobalStyle = createGlobalStyle`
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: ${(props) => props.theme.scrollbar.track_color};
    }

    *::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scrollbar.thumb_color};
      border-radius: 10px;
    }
  `;

  return (
    <ThemeProvider theme={darkMode ? theme_dark : theme_light}>
      <GlobalStyle theme={darkMode ? theme_dark : theme_light} />

      {ageVerified ? (
        <Router>
          <GlobalNav darkMode={darkMode} setDarkMode={setDarkMode}></GlobalNav>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/faq" element={<FaqScreen />} />
            <Route path="/mitgliedschaft" element={<MitgliedschaftInfo />} />

            <Route path="/warteliste" element={<Warteliste />} />
            <Route path="/waitlist" element={<Warteliste />} />
            <Route
              path="/waitlist/embedded"
              element={<Warteliste embedded />}
            />
            <Route
              path="/warteliste/verify/:token"
              element={<WartelisteMailBestätigung />}
            />
            <Route
              path="/waitlist/verify/:token"
              element={<WartelisteMailBestätigung />}
            />
            <Route
              path="/warteliste/remove/:token"
              element={<WartelisteBenutzerEntfernen />}
            />
            <Route
              path="/waitlist/remove/:token"
              element={<WartelisteBenutzerEntfernen />}
            />

            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
          <GlobalImpressum />
        </Router>
      ) : (
        <AgeVerification onAgeVerify={() => verifyAge()} />
      )}

      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
