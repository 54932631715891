import React from "react";
import styled from "styled-components";

const AgeVerification = ({ onAgeVerify = () => {} }) => {
  return (
    <AgeVerificationWrapper>
      <AgeVerificationTitle>Altersprüfung</AgeVerificationTitle>
      <AgerVerificationDescription>
        Um auf unser Angebot zugreifen zu können, musst du mindesten 18 Jahre
        alt sein. Bitte bestätige dein Alter.
      </AgerVerificationDescription>

      <AgeButtonWrapper>
        <AgeLink
          outline={true}
          href="https://www.bundesdrogenbeauftragter.de/service/beratungsangebote/"
        >
          unter 18
        </AgeLink>
        <AgeButton
          onClick={() => {
            onAgeVerify();
          }}
        >
          18+
        </AgeButton>
      </AgeButtonWrapper>
    </AgeVerificationWrapper>
  );
};

export default AgeVerification;

const AgeVerificationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.background_color};
`;

const AgeVerificationTitle = styled.h1`
  color: ${(props) => props.theme.title_color};
  font-size: 2rem;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const AgerVerificationDescription = styled.p`
  width: 25%;

  color: ${(props) => props.theme.description_color};
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 1200px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 80%;
    font-size: 1rem;
  }
`;

const AgeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AgeLink = styled.a`
  unset: all;
  outline: none;
  border: none;

  width: 180px;
  height: 45px;

  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;

  text-decoration: none;
  font-size: 1rem;
  font-family: "Heebo", sans-serif;

  color: ${(props) => props.theme.title_color};

  background-color: ${(props) =>
    props.outline ? "transparent" : props.theme.primary_color};

  border: 2px solid
    ${(props) => (props.outline ? props.theme.primary_color : "transparent")};

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  transition: transform 0.2s ease-in-out;
`;

const AgeButton = styled.button`
  unset: all;
  outline: none;
  border: none;

  width: 180px;
  height: 45px;

  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;

  text-decoration: none;
  font-size: 1rem;
  font-family: "Heebo", sans-serif;

  color: ${(props) => props.theme.title_color};

  background-color: ${(props) =>
    props.outline ? "transparent" : props.theme.primary_color};

  border: 2px solid
    ${(props) => (props.outline ? props.theme.primary_color : "transparent")};

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  transition: transform 0.2s ease-in-out;
`;
