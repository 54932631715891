import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, useScroll, useInView, useTransform } from "framer-motion";
import { MapPin } from "lucide-react";

import { TopSpacer } from "../components/GlobalNav";
import WaitlistButton from "../components/WaitlistButton.jsx";
import Magnetic from "../components/Magnetic";

import NewsIllustration from "../assets/images/NewsIllustration.png";

const HomeScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeScreenWrapper>
      <ApplicationTitle>CSC Sportzigarette</ApplicationTitle>
      <Banner />
      <Benefits />
      <Updates />

      <div style={{ height: "200px" }}></div>
    </HomeScreenWrapper>
  );
};

export default HomeScreen;

const HomeScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.background_color};
`;

const Banner = () => {
  return (
    <BannerWrapper>
      <BannerContentWrapper>
        <BannerTextWrapper>
          <LocatonIndicator />
          <BannerTitle>
            Bis zu 50 Gramm Cannabis, <br /> legal und jeden Monat!
          </BannerTitle>
          <BannerSubtitle>
            Für alle Mitglieder des “Sportzigarette” Cannabis Social Clubs
          </BannerSubtitle>
          <WaitlistButton />
        </BannerTextWrapper>

        <SplashGradient />
      </BannerContentWrapper>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 0;
  }
`;

const BannerContentWrapper = styled.div`
  position: relative;
  width: 90%;
  @media (min-width: 1600px) {
    width: 85%;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  z-index: 1;

  padding: 120px 0;

  @media (max-width: 768px) {
    min-height: 0;
  }
`;

const BannerTextWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const BannerTitle = styled.h1`
  font-size: 2.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.title_color};
  margin-top: 20px;
  font-family: "Switzer", sans-serif;

  @media (max-width: 768px) {
    font-size: 2.3rem;
  }
`;

const BannerSubtitle = styled.p`
  font-size: 1.15rem;
  font-weight: 500;
  color: ${(props) => props.theme.subtitle_color};
  margin-top: 30px;
  font-family: "Heebo", sans-serif;
  @media (max-width: 768px) {
  }
`;

const LocatonIndicator = () => {
  return (
    <LocaitonIndicatorWrapper>
      <LocationIndicatorIcon />
      <LocationIndicatorText>Dortmund</LocationIndicatorText>
    </LocaitonIndicatorWrapper>
  );
};

const LocaitonIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LocationIndicatorIcon = styled(MapPin)`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.primary_color_darker};
`;

const LocationIndicatorText = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.primary_color_darker};
  margin-left: 10px;
  font-family: "Heebo", sans-serif;
`;

const SplashGradient = () => {
  /*/
  const [animationProps, setAnimationProps] = useState({
    x: 0,
    y: 0,
    rotate: 0,
  });

  // Function to generate random values within a range
  const getRandomValue = (min, max) => Math.random() * (max - min) + min;

  // Update the animation properties at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationProps({
        x: getRandomValue(-50, 50), // Random X translation
        y: getRandomValue(-50, 50), // Random Y translation
        rotate: getRandomValue(-10, 10), // Random rotation
      });
    }, 1000); // Adjust interval for more/less frequent changes

    return () => clearInterval(interval);
  }, []);

  <motion.div
    animate={animationProps}
    transition={{
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    }}


  */

  return (
    <SplashGradientWrapper>
      <ColorSplash />
    </SplashGradientWrapper>
  );
};

const SplashGradientWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background_color};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  filter: blur(95px);
  transform: translate3d(0, 0, 0); // Fix for Safari
  z-index: -1;
`;

const ColorSplash = styled.div`
  width: 550px;
  max-width: 60%;
  height: 100px;

  margin-right: 50px;

  transform: rotate(-35deg);

  background: ${(props) =>
    ` linear-gradient(90deg, ${props.theme.primary_color} 0%, ${props.theme.primary_color_darker} 100%)`};
`;

/* End of Banner */

const Benefits = () => {
  //use scroll for cards
  const gridRef = useRef(null);

  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: gridRef,
    offset: ["start end", "1 0.5"],
  });

  const cardAmount = 0;
  const isCard1InView = useInView(gridRef, {
    once: true,
    amount: cardAmount,
    margin: "-500px 0px 0px 0px",
  });
  const isCard2InView = useInView(card2Ref, {
    once: true,
    amount: cardAmount,
    margin: "-500px 0px 0px 0px",
  });
  const isCard3InView = useInView(card3Ref, {
    once: true,
    amount: cardAmount,
    margin: "-500px 0px 0px 0px",
  });

  return (
    <BenefitsWrapper>
      <BenefitsTitle>
        Die Vorteile <br /> im überblick
      </BenefitsTitle>

      <BenefitsSubtitle>
        Eine Mitgliedschaft kann sich für dich auch lohnen, wenn du selber
        anbauen möchtest. Mehr dazu im <InTextLink to="/faq">FAQ</InTextLink>.
      </BenefitsSubtitle>

      <BenefitsGrid ref={gridRef}>
        <BenefitCard
          ref={card1Ref}
          initial={{ opacity: 0, y: 40 }}
          animate={isCard1InView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
          transition={{ duration: 0.5, delay: 0 }}
        >
          <BenefitCardTitle>Konsumfertiges Cannabis</BenefitCardTitle>
          <BenefitCardSubtitle>
            Bis zu 50g konsumfertiges Cannabis pro Monat einfach abholbereit an
            unserer Abgabestelle.
          </BenefitCardSubtitle>
          <BenefitCartSubSubtitle>
            Exotics - Haze - Kush - Classics - Hasch - Rosin
          </BenefitCartSubSubtitle>
        </BenefitCard>

        <BenefitCard
          ref={card2Ref}
          initial={{ opacity: 0, y: 40 }}
          animate={isCard2InView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BenefitCardTitle>Die besten Sorten</BenefitCardTitle>
          <BenefitCardSubtitle>
            Wir arbeiten eng mit der Community zusammen um eine gute Auswahl
            euer Lieblignssorten bereitzustellen.
          </BenefitCardSubtitle>
        </BenefitCard>

        <BenefitCard
          ref={card3Ref}
          initial={{ opacity: 0, y: 40 }}
          animate={isCard3InView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <BenefitCardTitle>Samen & Stecklinge</BenefitCardTitle>
          <BenefitCardSubtitle>
            Auch wenn du dich dazu entscheidest selber anzubauen, ist des
            praktisch, Mitglied in einem CSC zu werden. Von uns kannst du
            nämlich Samen und Stecklinge bekommen.
          </BenefitCardSubtitle>
        </BenefitCard>
      </BenefitsGrid>
    </BenefitsWrapper>
  );
};

const BenefitsWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.darkSection.background_color};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px 0;

  z-index: 2;
`;

const BenefitsTitle = styled.h1`
  width: 80%;

  text-align: center;
  font-size: 2.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.darkSection.title_color};
  margin-top: 20px;
  font-family: "Satoshi", sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2.2rem;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const BenefitsSubtitle = styled.p`
  width: 28%;
  min-width: 300px;
  text-align: center;
  font-size: 1.15rem;
  font-weight: 500;
  color: ${(props) => props.theme.darkSection.subtitle_color};
  margin-top: 30px;
  font-family: "Heebo", sans-serif;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1rem;
  }
  @media (max-width: 1000px) {
    width: 60%;
  }
`;

const InTextLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.primary_color};
`;

const BenefitsGrid = styled.div`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 50px 50px;
  margin-top: 150px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 100px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BenefitCard = styled(motion.div)`
  width: 30%;
  @media (max-width: 1000px) {
    width: 70%;
  }
  min-width: 250px;
  background-color: ${(props) => props.theme.darkSection.card_background_color};
  border-radius: 10px;
`;

const BenefitCardTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.darkSection.card_title_color};
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const BenefitCardSubtitle = styled.p`
  font-size: 1.15rem;
  font-weight: 500;
  color: ${(props) => props.theme.darkSection.card_subtitle_color};
  margin-top: 20px;
  font-family: "Heebo", sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BenefitCartSubSubtitle = styled(BenefitCardSubtitle)`
  font-weight: 200;
`;

/* End of Benefits */

const Updates = () => {
  const imageRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: imageRef,
    offset: ["start end", "1 0.9"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1.2, 1]);

  return (
    <UpdatesWrapper>
      <UpdatesContentWrapper>
        <UpdatesImage
          src={NewsIllustration}
          alt="CSC-Sportzigarette"
          ref={imageRef}
          style={{
            scale: scale,
          }}
          onDragStart={(e) => e.preventDefault()}
        />

        <UpdatesTextWrapper>
          <UpdatesTitle>
            Wir halten euch auf dem <br /> Laufenden!
          </UpdatesTitle>
          <UpdatesSubtitle>
            Hier findet ihr alle News rund um den CSC-Sportzigarette.
          </UpdatesSubtitle>
          <UpdatesButton to="/news">Zu den News</UpdatesButton>
        </UpdatesTextWrapper>
      </UpdatesContentWrapper>
    </UpdatesWrapper>
  );
};

const UpdatesWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px 0;

  @media (max-width: 768px) {
    min-height: 0;
  }
`;

const UpdatesContentWrapper = styled.div`
  width: 80%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const UpdatesImage = styled(motion.img)`
  height: 400px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const UpdatesTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const UpdatesTitle = styled.h1`
  font-size: 2.1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  font-weight: 700;
  color: ${(props) => props.theme.title_color};
  margin-top: 20px;
  font-family: "Satoshi", sans-serif;
`;

const UpdatesSubtitle = styled.p`
  font-size: 1.15rem;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
  font-weight: 500;
  color: ${(props) => props.theme.subtitle_color};
  margin-top: 20px;
  font-family: "Heebo", sans-serif;
`;

const UpdatesButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 12px 20px;
  margin-top: 25px;

  border-radius: 10px;
  background-color: ${(props) => props.theme.title_color};

  color: ${(props) => props.theme.background_color};
  font-size: 0.85rem;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
`;

const ApplicationTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ApplicationTitle = styled.h1`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Heebo", sans-serif;
  color: ${(props) => props.theme.navbar.text_color};
  z-index: 10;

  @media (max-width: 1000px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: initial;
    font-size: 1rem;
    top: 20px;
  }
`;
