const primary_color_light = "#9DBF9E";
const primary_color_darker_light = "#829E82";
const primary_color_dark = "#718A72";
const primary_color_darker_dark = "#617762";

export const theme_dark = {
  state: "dark",
  background_color: "#101010",
  title_color: "#FFFFFF",
  subtitle_color: "#C5C5C5",
  description_color: "#EEEEEE",
  divider_color: "#3F3F3F",
  primary_color: primary_color_dark,
  primary_color_darker: primary_color_darker_dark,
  inverted_background_color: "#FAFAFA",
  inverted_title_color: "#000000",
  darkSection: {
    background_color: "#000000",
    title_color: "#FFFFFF",
    subtitle_color: "#DBDBDB",
    card_title_color: "#FFFFFF",
    card_subtitle_color: "#DBDBDB",
  },
  impressum: {
    background_color: "#000000",
    title_color: "#EEEEEE",
    link_color: "#C9C9C9",
  },
  faq_item: {
    background_color: "#1F1F1F",
    hover_background_color: "#222222",
    title_color: "#FFFFFF",
    text_color: "#EEEEEE",
  },
  navbar: {
    background_color: "#151515",
    text_color: "#E8E8E8",
    button_selected_background_color: "#D1D1D1",
    button_selected_text_color: "#000000",
  },
  scrollbar: {
    thumb_color: "#4F4F4F",
    track_color: "#101010",
  },
  textfield: {
    background_color: "#202020",
    border_color: "#636363",
    label_color: "#0056FF",
    text_color: "#EEEEEE",
    title_color: "#EEEEEE",
    description_color: "#AFAFAF",
    validation: {
      valid_color: "#61A561",
      invalid_color: "#D25858",
    },
  },
  slider: {
    background_color: "#202020",
    thumb_color: "#EEEEEE",
  },
  selectionItem: {
    background_color_notSelected: "transparent",
    border_color_notSelected: "#606060",
    background_color_selected: "rgba(157, 191, 158, 0.1)",
    border_color_selected: "#9DBF9E",
    title_color: "#EEEEEE",
  },
  checkbox: {
    background_color: "#202020",
    background_color_hover: "#202020",
    border_color: "#636363",
    checkmark_color: "#EEEEEE",
    text_color: "#EEEEEE",
  },
  page: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));",
  },
  buttons_new: {
    dominant: {
      background_color: primary_color_dark,
      background_color_hover: "#DC323D",
      text_color: "#EEEEEE",
    },
  },
  buttons: {
    standard: {
      background: "#292929",
      hover: "#252525",
      text: "#EEEEEE",
    },
    onWhite: {
      background: "#292929",
      hover: "#252525",
      text: "#EEEEEE",
    },
    outline: {
      border: "#2E2E2E",
      hover: "#292929",
      text: "#EEEEEE",
    },
    ghost: {
      hover: "#292929",
      text: "#EEEEEE",
    },
    dominant: {
      background: "#FF3A47",
      hover: "#DC323D",
      text: "#EEEEEE",
    },
  },
  popup: {
    pageOverlayColor: "rgba(0, 0, 0, 0.4)",
    border: "#555555",
    background: "rgba(19, 19, 19, 1.0)",
    text: "#fff",
    sidebar: {
      background: "#191919",
      selectedElement: "#1F1F1F",
      text: "#CFCFCF",
      icon: "#CFCFCF",
      hambugerBackground: "#000000",
    },
  },
};

export const theme_light = {
  state: "light",
  background_color: "#FAFAFA",
  title_color: "#000000",
  subtitle_color: "#454545",
  description_color: "#191919",
  divider_color: "#D9D9D9",
  primary_color: primary_color_light,
  primary_color_darker: primary_color_darker_light,
  inverted_background_color: "#101010",
  inverted_title_color: "#FFFFFF",
  darkSection: {
    background_color: "#000000",
    title_color: "#FFFFFF",
    subtitle_color: "#D6D6D6",
    card_title_color: "#FFFFFF",
    card_subtitle_color: "#F0F0F0",
  },
  impressum: {
    background_color: "#F0F0F0",
    title_color: "#000000",
    link_color: "#656565",
  },
  faq_item: {
    background_color: "#F1F1F1",
    hover_background_color: "#EDEDED",
    title_color: "#000000",
    text_color: "#191919",
  },
  navbar: {
    background_color: "#E8E8E8",
    text_color: "#000000",
    button_selected_background_color: "#000000",
    button_selected_text_color: "#FFFFFF",
  },
  scrollbar: {
    thumb_color: "#B3B3B3",
    track_color: "#FAFAFA",
  },
  textfield: {
    background_color: "#F1F1F1",
    border_color: "transparent",
    label_color: "#0056FF",
    title_color: "#191919",
    text_color: "#050505",
    description_color: "#7F7F7F",
    validation: {
      valid_color: "#61A561",
      invalid_color: "#D25858",
    },
  },
  slider: {
    background_color: "#DADADA",
    thumb_color: "#414141",
    thumb_color_hover: "#191919",
  },
  selectionItem: {
    background_color_notSelected: "#F1F1F1",
    border_color_notSelected: "#CECECE",
    background_color_selected: "rgba(157, 191, 158, 0.1)",
    border_color_selected: "#9DBF9E",
    title_color: "#000000",
  },
  checkbox: {
    background_color: "#DFDFDF",
    background_color_hover: "#CECECE",
    border_color: "#636363",
    checkmark_color: "#000",
    text_color: "#484848",
  },
  page: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.03));",
  },
  buttons_new: {
    dominant: {
      background_color: primary_color_light,
      background_color_hover: "#DC323D",
      text_color: "#FFFFFF",
    },
  },
  buttons: {
    standard: {
      background: "#fff",
      hover: "#CACACA",
      text: "#000",
    },
    onWhite: {
      background: "#E6E6E6",
      hover: "#D6D6D6",
      text: "#000",
    },
    outline: {
      border: "#CACACA",
      hover: "#F1F1F1",
      text: "#000",
    },
    ghost: {
      hover: "#F1F1F1",
      text: "#000",
    },
    dominant: {
      background: "#FF3A47",
      hover: "#DC323D",
      text: "#EEEEEE",
    },
  },
  popup: {
    pageOverlayColor: "rgba(0, 0, 0, 0.4)",
    background: "rgba(255, 255, 255, 1.0)",
    border: "#CACACA",
    text: "#252525",
    sidebar: {
      background: "#FAFAFA",
      selectedElement: "#EEEEEE",
      text: "#252525",
      icon: "#252525",
      hambugerBackground: "#FAFAFA",
    },
  },
};
