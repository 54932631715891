import React, { useEffect } from "react";
import { styled } from "styled-components";

import { TopSpacer } from "../components/GlobalNav";
import {
  HeaderWrapper,
  PageTitle,
  PageDescription,
  PageSubDescription,
  BrandedLink,
} from "../components/PageGenerics";
import WaitlistButton from "../components/WaitlistButton";

const MitgliedschaftInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MitgliedschaftInfoWrapper>
      <TopSpacer />

      <HeaderWrapper>
        <PageTitle>Mitgliedschaft</PageTitle>
        <PageDescription>
          Hier sind die wichtigsten Infos zur Mitgliedschaft bei uns
        </PageDescription>
      </HeaderWrapper>

      <div style={{ height: "100px" }} />

      <MitgliedschaftInfoContentWrapper>
        <MitgliedschaftTitle>
          Warum kann ich jetzt schon Mitglied werden?
        </MitgliedschaftTitle>
        <MitgliedschaftDescription>
          Bis wir unsere Anbauerlaubnis bekommen, sind einige Vorbereitungen zu
          treffen.
          <br />
          <br />
          Wir bauen Anbauräume, richten eine Abgabestelle ein, suchen schon nach
          den bestens Sorten für dich und vieles mehr. Bei all dem können wir
          deine Hilfe gebrauchen!
          <br />
          <br />
          Je mehr Mitglieder wir jetzt schon überzeugen können, desto leichter
          wird es auch für unsere Investoren, die Finanzierung sicherzustellen.
        </MitgliedschaftDescription>
      </MitgliedschaftInfoContentWrapper>

      <MitgliedschaftInfoContentWrapper>
        <MitgliedschaftTitle>
          Ab wann kann ich Cannabis bekommen?
        </MitgliedschaftTitle>
        <MitgliedschaftDescription>
          Nach dem aktuellen Plan der Bundesregierung können wir ab dem{" "}
          <b>01. Juli 2024</b> unsere Anbau Erlaubnis beantragen. Sobald wir
          diese haben können wir Endlich Produkte an unsere Mitglieder ausgeben.
        </MitgliedschaftDescription>
      </MitgliedschaftInfoContentWrapper>

      <MitgliedschaftInfoContentWrapper>
        <MitgliedschaftTitle>Was kostet das?</MitgliedschaftTitle>
        <MitgliedschaftDescription>
          Solange wir dir noch kein Gras anbieten können, bleibt alles kostenlos
          für dich. Du unterstützt den Club schon mit deiner reinen
          Mitgliedschaft! Sobald die Produktion läuft, passen wir deinen
          Mitgliedsbeitrag so an, dass er zu deinem Konsum passt. Wir rechnen
          mit Kosten von 5 bis 15€/Gramm, je nach Sorte.
          <br />
          <br />
          Wenn du dir jetzt schon Zugriff auf das erste produzierte Gras sichern
          willst oder einfach Lust hast mitzuhelfen, kannst du einen
          freiwilligen Mitgliedsbeitrag leisten.
          <br />
          <br />
          Mehr Infos dazu bekommst du auf deiner Club Einladung.
        </MitgliedschaftDescription>
      </MitgliedschaftInfoContentWrapper>

      <MitgliedschaftInfoContentWrapper>
        <MitgliedschaftTitle>
          Vorraussetzungen für die Mitgliedschaft
        </MitgliedschaftTitle>
        <MitgliedschaftDescription>
          Es gibt einige Bedingungen, die du erfüllen musst um Mitglied in einem
          CSC zu werden:
          <br />
          <br />
          - Du bist Volljährig (mindestens 18 Jahre) <br />- Du hast einen
          festen Wohnsitz in Deutschland (seit min. 6 Monaten) <br />
          - Du bist in keinem anderen CSC Mitglied <br />
        </MitgliedschaftDescription>
      </MitgliedschaftInfoContentWrapper>

      <MitgliedschaftInfoContentWrapper>
        <MitgliedschaftTitle>Wie werde ich Mitglied?</MitgliedschaftTitle>
        <MitgliedschaftDescription>
          Um dich zu “bewerben” brauchst du nur ein paar Fragen zu beantworten
          und erhälst dann bald eine Einladung von uns.
          <br />
          <br />
          Jeder CSC darf nur 500 Mitglieder aufnehmen, deswegen können wir nicht
          jede Bewerbung direkt annehmen.
        </MitgliedschaftDescription>

        <WaitlistButton />
      </MitgliedschaftInfoContentWrapper>

      <div style={{ height: "100px" }} />
    </MitgliedschaftInfoWrapper>
  );
};

export default MitgliedschaftInfo;

const MitgliedschaftInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.background_color};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const MitgliedschaftInfoContentWrapper = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

const MitgliedschaftTitle = styled.h2`
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.title_color};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const MitgliedschaftDescription = styled.p`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.description_color};
  font-size: 1rem;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
