import { styled } from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.h1`
  width: 90%;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
  font-family: "Switzer", sans-serif;
  color: ${(props) => props.theme.title_color};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const PageDescription = styled.p`
  width: 30%;
  text-align: center;
  color: ${(props) => props.theme.description_color};
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 1rem;
  }
`;

export const PageSubDescription = styled.p`
  width: 30%;
  text-align: center;
  color: ${(props) => props.theme.description_color};
  font-size: 1rem;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  margin-bottom: 20px;
  opacity: 0.8;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 0.9rem;
  }
`;

export const BrandedLink = styled(Link)`
  unset: all;
  text-decoration: none;
  color: ${(props) => props.theme.primary_color};
`;
